import api from '@/services/api';
import { mapActions, mapGetters } from 'vuex';
import ConfirmationModal from '@/components/Partials/ConfirmationModal/ConfirmationModal.vue';
import InsufficientFoundModal from '@/components/Partials/InsufficientFoundModal/InsufficientFoundModal.vue';
import router from '@/router';
import Package from './Package/Package.vue';

export default {
    components: {
        Package,
        ConfirmationModal,
        InsufficientFoundModal,
    },

    data() {
        return {
            packages: {
                data: [],
                bonus: [],
                roaming: [],
            },
            showInsufficientFoundModal: false,
            modalMessage: "",
            bundleId: null,
            isPackageButtonClicked: false,
            packageAction: ""
        };
    },

    computed: {
        ...mapGetters({
            locale: 'locale',
            contract: 'contract/contract',
        }),
    },

    watch: {
        /**
         * When language is changed, then call again api call with new locale
         */
        locale() {
            this.getPackages();
        },

        /**
         * Listen for object changed for clicked contract
         */
        'contract.clickedContract.phoneNumber': {
            handler() {
                this.getPackages();
            },
            deep: true,
        },
    },

    methods: {
        ...mapActions('user', ['setUserDataAfterUpdating']),

        /**
         * Get all packages on component mounted
         */
        getPackages() {
            this.$Progress.start();
            api.get('/api/bundles')
                .then((response) => {
                    this.packages.data = response.data.body.bundles.data;
                    this.packages.bonus = response.data.body.bundles.bonus;
                    this.packages.roaming = response.data.body.bundles.roaming;

                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },

        /**
         * Send bundle ID to our server then
         */
        sendPackageIdAndOpenModalForm() {
            switch (this.packageAction) {
                case 'activate':
                    this.activatePackage();

                    break;
                case 'deactivate':
                    this.deactivatePackage();
                    
                    break;      
                default:
                    break;
            }
        },


        /**
         * Send bundle ID to deactivate
         */
         deactivatePackage() {
            this.isPackageButtonClicked = false;
            this.packageAction = "";

            const params = new URLSearchParams();
            params.append('plan_id', this.bundleId);
            params.append('type', 'bundle');
            api.post(`/api/bundles/deactivate/${this.bundleId}`, params)
                .then(() => {
                    this.$Progress.finish();
                    this.$toast.success(this.$i18n.t('messages.success'));
                    this.getPackages();
                    this.setUserDataAfterUpdating();
                }).catch((error) => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },

        /**
         * Send bundle ID to activate
         */
         activatePackage() {
            this.isPackageButtonClicked = false;
            this.packageAction = "";

            const params = new URLSearchParams();
            params.append('plan_id', this.bundleId);
            params.append('type', 'bundle');
            api.post(`/api/bundles/activate/${this.bundleId}`, params)
                .then(() => {
                    this.$Progress.finish();
                    this.$toast.success(this.$i18n.t('messages.success'));
                    this.getPackages();
                    this.setUserDataAfterUpdating();
                }).catch((error) => {
                    if (error.response.data.code === 'ACTIVATE_BUNDLE_FAILED_INSUFFICIENT_FOUND') {
                        this.showInsufficientFoundModal = true;
                        this.modalMessage = error.response.data.body.message;
                    } else {
                        this.$toast.error(this.$i18n.t('messages.error'));
                    }
                    this.$Progress.finish();
                });
        },

        /**
         * Close showInsufficientFoundModal modal
         */
        closeModal() {
            this.showInsufficientFoundModal = false;
            this.modalMessage = ""
        },

        /**
         * Open confirmation modal
         */
        openConfirmationModal(bundleId) {
            this.bundleId = bundleId;
            this.isPackageButtonClicked = true;
            this.packageAction = "activate";
        },

        /**
         * Open deactivate confirmation modal
         */
         openDeactivateConfirmationModal(bundleId) {
            this.bundleId = bundleId;
            this.isPackageButtonClicked = true;
            this.packageAction = "deactivate";
        },

        /**
         * Close confirmation modal
         */
        closeConfirmationModal() {
            this.isPackageButtonClicked = false;
        },

        /**
         * Redirect user to recharge-credit page
         */
        redirectAccepted() {
            return router.push('recharge-credit');
        },
    },

    mounted() {
        this.getPackages();
    },
};
