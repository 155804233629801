export default {
    props: [
        'id',
        'name',
        'description',
        'locale',
        'duration',
        'price',
        'recurring',
        'active'
    ],

    methods: {
        activate(packageId) {
            this.$emit('activate', packageId);
        },

        openModal(bundleId) {
            this.$emit('openModal', bundleId);
        },

        openDeactivateModal(bundleId) {
            this.$emit('openDeactivateModal', bundleId);
        },
    },
};
