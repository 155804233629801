<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">

            <main class="main-content layout-calc">
                <div class="row">
                    <!-- DATA PACKAGES-->
                    <div class="col-md-6 col-xl-4 col-margin-bottom-20" v-for="additionalPackage in packages.data" :key="additionalPackage.productId">
                        <Package
                            :id="additionalPackage.productId"
                            :name="additionalPackage.name"
                            :description="additionalPackage.description"
                            :locale="locale"
                            :duration="additionalPackage.duration"
                            :price="additionalPackage.price"
                            :recurring="additionalPackage.recurring"
                            :active="additionalPackage.active"
                            @openModal="openConfirmationModal(additionalPackage.bundleCode)"
                            @openDeactivateModal="openDeactivateConfirmationModal(additionalPackage.bundleCode)"
                        />
                    </div>
                    <!--BONUS PACKAGES-->
                    <div class="col-md-6 col-xl-4 col-margin-bottom-20" v-for="additionalPackage in packages.bonus" :key="additionalPackage.productId">
                        <Package
                            :id="additionalPackage.productId"
                            :name="additionalPackage.name"
                            :description="additionalPackage.description"
                            :locale="locale"
                            :duration="additionalPackage.duration"
                            :price="additionalPackage.price"
                            :recurring="additionalPackage.recurring"
                            :active="additionalPackage.active"
                            @openModal="openConfirmationModal(additionalPackage.bundleCode)"
                            @openDeactivateModal="openDeactivateConfirmationModal(additionalPackage.bundleCode)"
                        />
                    </div>
                    <!--ROAMING PACKAGES-->
                    <div class="col-md-6 col-xl-4 col-margin-bottom-20" v-for="additionalPackage in packages.roaming" :key="additionalPackage.productId">
                        <Package
                            :id="additionalPackage.productId"
                            :name="additionalPackage.name"
                            :description="additionalPackage.description"
                            :locale="locale"
                            :duration="additionalPackage.duration"
                            :price="additionalPackage.price"
                            :recurring="additionalPackage.recurring"
                            :active="additionalPackage.active"
                            @openModal="openConfirmationModal(additionalPackage.bundleCode)"
                            @openDeactivateModal="openDeactivateConfirmationModal(additionalPackage.bundleCode)"
                        />
                    </div>

                </div>
                <div class="row"
                     v-if="!Object.keys(packages.roaming).length && !Object.keys(packages.bonus).length && !Object.keys(packages.data).length"
                >
                    <p>
                        {{ $t('pages.additionalPackages.noPackages') }}
                    </p>
                </div>

                <ConfirmationModal
                    v-if="isPackageButtonClicked"
                    @close="closeConfirmationModal"
                    @paymentAgree="sendPackageIdAndOpenModalForm"
                />

                <InsufficientFoundModal
                    :message="modalMessage"
                    @close="closeModal"
                    @redirectAccepted="redirectAccepted"
                    v-if="showInsufficientFoundModal"
                />
            </main>

        </div>
    </section>
</template>

<script>
import AdditionalPackages from './AdditionalPackages';
export default AdditionalPackages;
</script>
